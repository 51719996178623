<template>
  <vf-input v-bind="{ disabled, invalid, variant }" :required>
    <slot>{{ $t.birthDate }}</slot>
    <template #input>
      <base-input
        ref="inputRef"
        v-bind="{ disabled, placeholder, required }"
        v-model="maskedDate"
        aria-describedby="birthDate"
        @change="onChange"
      />
    </template>
    <template #end>
      <slot name="end" />
    </template>
  </vf-input>
</template>

<script lang="ts" setup>
/**
 * This component expect the model value to be a string in the format YYYY-MM-DD
 * It will emit the value in the same format, YYYY-MM-DD
 * Locale formats are handled internally and should be specified as a prop when adding EMEA support
 */
const props = withDefaults(defineProps<{
  modelValue: string
  placeholder?: string
  disabled?: boolean
  invalid?: boolean
  variant?: 'inverse'
  displayFormat?: 'MM/DD/YYYY' | 'DD/MM/YYYY'
  required?: boolean
}>(), { displayFormat: 'MM/DD/YYYY' })

const emit = defineEmits<{
  'update:modelValue': [payload: string]
  'change': [value: string]
}>()

const { value: maskedDate, el: inputRef } = useMask('XX/XX/XXXX', {
  regex: /\d/
})

// Temporary solution to emit 'change' event
// 'update:modelValue' should be emitted on 'input' event
const onChange = () => {
  const formatted = dateFormat(maskedDate.value, props.displayFormat, 'YYYY-MM-DD')

  emit('update:modelValue', formatted)
  emit('change', formatted)
}

watch(() => props.modelValue, (value) => {
  // the model value should be ALWAYS a string in the format YYYY-MM-DD
  // this watcher will convert the value to the displayFormat
  maskedDate.value = dateFormat(value, 'YYYY-MM-DD', props.displayFormat)
}, { immediate: true })
</script>
